import { colorNames } from 'app/app.styles';
import styled, { createGlobalStyle } from 'styled-components';
import kendoCommonStyles from 'app/styles/kendo-components-common.scss';

export const ComboBoxWrapper = styled.div`
    .k-combobox.k-input {
        height: 30px;
        ${(props) => (props.isCustomValueTemplate ? 'display: inline-block;' : '')}
        box-shadow: none;
        .k-icon {
            color: #787878;
        }
        
        border-radius: 0px;
        border-color: var(${colorNames.ZenGrey});
        .k-button {
            color: #787878;
            border: none;
            background-color: white;

            :hover, .k-button:active {
                background-color: var(${colorNames.ZenCyan});
                border-color: var(${colorNames.ZenCyan});
                .k-button-icon {
                    color: white;
                }
            }

            .k-icon.k-i-caret-alt-down {
                min-height: unset;
            }

        }
        input {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
            ::placeholder {
                opacity: 0.4;
            }
        }
    }
`;

export const ComboBoxGlobalStyles = createGlobalStyle(`
    ${kendoCommonStyles}
`);
