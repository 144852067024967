/* eslint-disable no-const-assign */
/* eslint-disable no-param-reassign */
import { DropDownList as KendoDropDownList } from '@progress/kendo-react-dropdowns';
import React, { useState, useEffect } from 'react';
import { getDescendantProp } from 'utils/string';
import MOTApi from 'utils/motApi';
import { useQueryClient } from 'react-query';
import { DropDownListGlobalStyle, DropDownListWrapper } from './drop-down-list.styles';

let isDisabledSelected;

export const DropDownListTemplate = (li, liProps, itemChildren) => React.cloneElement(li, liProps, itemChildren);

export const DropDownList = React.forwardRef((props, ref) => {
  const {
    onChange,
    allowArrows = true,
    data,
    url,
    postDataRef,
    onRefChange,
    useQuery,
    queryKey,
    width,
  } = props;
  let { itemRender } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [comboBoxData, setData] = useState([]);

  useEffect(() => {
    if (url && !data) {
        MOTApi.post(url, {
            ...postDataRef?.current,
        }).then((resp) => {
            setData(resp.data.dataSource);
        });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [setData]);

  const dataSource = data ?? comboBoxData;

  let onClose = (e) => {
    if (props.onClose) {
      props.onClose(e);
    }
    setIsOpen(false);
  };
  const onOpen = (e) => {
    if (props.onOpen) {
      props.onOpen(e);
    }

    setIsOpen(true);
  };

  const disabledItemRender = (itemTemplate, li, itemProps, disabledSelector) => {
    let disabledProps = li.props;
    if (itemProps.dataItem[disabledSelector]) {
      disabledProps = { ...disabledProps, className: `${disabledProps.className} drop-down-disabled-item` };
    }
    const template = itemTemplate(li, disabledProps, itemProps);

    return template;
  };

  const onCloseDisabled = (e, onCloseF) => {
    if (isDisabledSelected) {
      return;
    }

    if (onCloseF) {
      onCloseF(e);
    }
    setIsOpen(false);
  };

  if (props.itemDisabled) {
      onClose = (e) => onCloseDisabled(e, props.onClose);

      itemRender = (li, itemProps) => disabledItemRender(props.itemRender, li, itemProps, props.itemDisabled);
  }

  const queryClient = useQueryClient();
  const [valueInner, setValueInner] = useState(props.value);
  const setValue = useQuery ? (newData) => queryClient.setQueryData(queryKey, newData) : (newData) => setValueInner(newData);
  const value = useQuery ? props.value : valueInner;

  const onRefChangeF = (node) => {
    if (node !== null && ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = node;
      const current = ref?.current;
      if (current) {
        current.setValue = (v) => {
          setValue(v);
        };

        if (onRefChange) {
          onRefChange(current);
      }
      }
    }
  };

  const onChangeF = (event) => {
    const { code } = event.syntheticEvent;
    let newValue = event.target.value;
    let isValueValid = true;
    if (props.itemDisabled && getDescendantProp(newValue, props.itemDisabled)) {
        const index = dataSource.indexOf(newValue);
        isDisabledSelected = true;
        setTimeout(() => {
            isDisabledSelected = false;
        });

        if (code === 'ArrowDown' || code === 'ArrowUp') {
            let nextValue;
            if (code === 'ArrowDown') {
                nextValue = dataSource.slice(index).find((x) => !getDescendantProp(x, props.itemDisabled));
            } else if (code === 'ArrowUp') {
                nextValue = dataSource.slice(0, index).findLast((x) => !getDescendantProp(x, props.itemDisabled));
            }
            isValueValid = !!nextValue;
            if (!nextValue) {
                nextValue = dataSource;
            }
            newValue = nextValue;
        } else {
            newValue = dataSource;
        }
    }

    if (isValueValid) {
        setValue(newValue);
        // eslint-disable-next-line no-param-reassign
        event.disabledValue = newValue;
    }
    return isValueValid;
  };

  return <DropDownListWrapper data-test-id={props['data-test-id']} height={props.height}>
      <DropDownListGlobalStyle/>
      <KendoDropDownList {...props}
        data={dataSource}
        value={value}
        ref={onRefChangeF}
        fillMode='flat'
        onClose={onClose}
        onOpen={onOpen}
        onChange={(e) => {
          const { code } = e.syntheticEvent;
          const enableOnChange = (code !== 'ArrowDown' && code !== 'ArrowUp');
          if (!allowArrows && !isOpen.current && !enableOnChange) {
              return;
          }
          const isValid = onChangeF(e, !enableOnChange);
          if (isValid && (enableOnChange || !isOpen)) {
              onChange && onChange(e);
          }
        }}
        itemRender={itemRender}
        opened={isOpen}
        rounded={null}
        style={{
          width,
      }}
      />
    </DropDownListWrapper>;
});
